import * as React from 'react'
import styled from 'styled-components'

export const PictureFrame = styled.div`
margin: 0 auto;
width: 60vw;
`
export const Content = styled.div`
width: 60vw;
margin: 0 auto;
padding-bottom: 1em;
`
