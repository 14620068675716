import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import { Content, PictureFrame } from '../styles/common'
// markup
const NotFoundPage = () => {
    return (
        <Layout pageTitle="That's not here...">
            <Content>
                <PictureFrame>
                    <StaticImage src='../images/garfwave.png' alt='wow how did this get here?' />
                </PictureFrame>
            </Content >
        </Layout >
    )
}

export default NotFoundPage
